<template>
  <div id="clothOutput" class="clothOutput">
    <!-- 顶部筛选 -->
    <div class="filter">
      <div class="left">
        <div class="filterItem">
          <div style="text-align: right; margin-right: 10px">合同号</div>
          <Input
            placeholder="请输入搜索内容"
            v-model.trim="contractNum"
            style="width: 210px"
            @keyup.enter.native="tableDataList1"
          />
        </div>
        <div class="filterItem">
          <div style="text-align: right; margin-right: 10px">坯布货号</div>
          <Input
            placeholder="请输入搜索内容"
            v-model.trim="articleNumber"
            style="width: 210px"
            @keyup.enter.native="tableDataList1"
          />
        </div>
        <div class="filterItem">
          <div style="text-align: right; margin-right: 10px">坯布指示单号</div>
          <Input
            placeholder="请输入搜索内容"
            v-model.trim="planOrderNumber"
            style="width: 210px"
            @keyup.enter.native="tableDataList1"
          />
        </div>

        <div class="filterItem">
          <div
            style="text-align: right; margin-right: 10px"
            @keyup.enter.native="tableDataList1"
          >
            单据日期
          </div>
          <DatePicker
            type="daterange"
            placeholder="请选择"
            style="width: 220px"
            v-model="docDate"
          ></DatePicker>
        </div>
        <div class="filterItem">
          <div style="text-align: right; margin-right: 10px">单据编号</div>
          <Input
            placeholder="请输入搜索内容"
            v-model.trim="docNumber"
            style="width: 210px"
            @keyup.enter.native="tableDataList1"
          />
        </div>
        <div class="filterItem">
          <div style="text-align: right; margin-right: 10px">单据类型</div>
          <Select
            slot="list"
            v-model="docType"
            style="width: 150px"
            @on-change="tableDataList1"
          >
            <Option
              v-for="item in docNumberSelectList"
              :value="item"
              :key="item"
            >
              {{ item }}
            </Option>
          </Select>
        </div>
        <div class="filterItem">
          <div style="text-align: right; margin-right: 10px">状态</div>
          <Select
            slot="list"
            v-model="status"
            style="width: 150px"
            @on-change="tableDataList1"
          >
            <Option v-for="item in statusSelectList" :value="item" :key="item">
              {{ item }}
            </Option>
          </Select>
        </div>
        <div class="filterItem">
          <Button @click="handleClickfilter">
            自定义筛选
            <i v-if="!showUserDefined" class="iconfont iconicon-xia"></i>
            <i v-if="showUserDefined" class="iconfont iconicon-shang"></i>
          </Button>
        </div>
      </div>
    </div>
    <!--自定义筛选显示-->
    <div class="userDefined" v-if="showUserDefined">
      <!-- <div class="triangle"></div> -->
      <div class="left">
        <div
          class="select_container"
          v-if="showSelect"
          style="margin-bottom: 10px"
        >
          <Checkbox
            :indeterminate="indeterminate1"
            :value="checkAll1"
            @on-change="handleCheckAll1"
            >全选</Checkbox
          >
          <div class="divide"></div>
          <CheckboxGroup
            v-model="checkAllGroup1"
            @on-change="checkAllGroupChange1"
          >
            <Checkbox
              :label="item.name"
              v-for="item in boxList1"
              :key="item.name"
            ></Checkbox>
          </CheckboxGroup>
        </div>

        <div class="filter_container" v-else>
          <div
            class="filterItem"
            v-if="checkAllGroupShow1.includes('单位名称')"
          >
            <div style="margin-right: 10px; line-height: 32px">单位名称</div>
            <Input
              placeholder="请输入搜索内容"
              v-model.trim="unitName"
              style="width: 210px"
              @keyup.enter.native="tableDataList1"
            />
          </div>

          <div class="filterItem" v-if="checkAllGroupShow1.includes('担当')">
            <div style="margin-right: 10px; line-height: 32px">担当</div>
            <Input
              placeholder="请输入搜索内容"
              v-model.trim="takeOn"
              style="width: 210px"
              @keyup.enter.native="tableDataList1"
            />
          </div>

          <div
            class="filterItem"
            v-if="checkAllGroupShow1.includes('应付单号')"
          >
            <div style="margin-right: 10px; line-height: 32px">应付单号</div>
            <Input
              placeholder="请输入搜索内容"
              v-model.trim="receiptNumber"
              style="width: 210px"
              @keyup.enter.native="tableDataList1"
            />
          </div>

          <div class="filterItem" v-if="checkAllGroupShow1.includes('品名')">
            <div style="margin-right: 10px; line-height: 32px">品名</div>
            <Input
              placeholder="请输入搜索内容"
              v-model.trim="productNumber"
              style="width: 210px"
              @keyup.enter.native="tableDataList1"
            />
          </div>

          <div class="filterItem" v-if="checkAllGroupShow1.includes('颜色')">
            <div style="margin-right: 10px; line-height: 32px">颜色</div>
            <Input
              placeholder="请输入搜索内容"
              v-model.trim="color"
              style="width: 210px"
              @keyup.enter.native="tableDataList1"
            />
          </div>

          <div class="filterItem" v-if="checkAllGroupShow1.includes('机台号')">
            <div style="margin-right: 10px; line-height: 32px">机台号</div>
            <Input
              placeholder="请输入搜索内容"
              v-model.trim="deviceNumber"
              style="width: 210px"
              @keyup.enter.native="tableDataList1"
            />
          </div>
        </div>
      </div>
      <div class="right">
        <!-- <Button class="cancel" v-if="showSelect">取消</Button> -->
        <Button type="primary" v-if="showSelect" @click="handleClickConfirm"
          >确定</Button
        >
      </div>
    </div>
    <!-- 状态单选 -->
    <!-- <div class="box">
      <div class="boxItem">
        <span style="margin-right: 10px">状态：</span>
        <Checkbox
          :indeterminate="indeterminate2"
          :value="checkAll2"
          @click.prevent.native="handleCheckAll2"
          >全选</Checkbox
        >
        <CheckboxGroup v-model="checkAllGroup2" @on-change="checkAllGroupChange2">
          <Checkbox
            :label="item.name"
            v-for="item in boxList2"
            :key="item.name"
          ></Checkbox>
        </CheckboxGroup>
      </div>

    </div>-->

    <!-- 按钮 -->
    <div class="button">
      <div class="buttonRow">
        <div class="left">
          <div class="item" @click="syncTableData">
            <i class="iconfont iconicon-tongbu" />
            同步
          </div>
          <div class="item" @click="handleClickExport">
            <i class="iconfont iconicon-daochu" />
            导出
          </div>
          <div class="item" @click="handleClickTableSetting('main')">
            <i class="iconfont iconicon-chuansuokuang" />
            表格设置
          </div>
          <div class="item" @click="tableDataList1">
            <i class="iconfont iconquerenshaixuan"></i>
            确认筛选
          </div>
        </div>
      </div>
      <!-- 可以扩展  -->
    </div>

    <!-- 表格 -->
    <Table
      :columns="tableColumns1"
      :loading="loading1"
      :data="tableData1"
      border
      show-summary
      :summary-method="handleSummary1"
    ></Table>

    <!-- 分页 -->
    <Page
      class-name="page"
      :total="listData1.total"
      show-elevator
      show-sizer
      show-total
      :page-size="listData1.pageSize"
      :current="listData1.pageNum"
      @on-page-size-change="pageSizeChange1"
      @on-change="pageNumberChange1"
    />
    <!-- 下方的抽屉弹窗 -->
    <el-drawer
      title="出仓明细表"
      size="570px"
      :visible.sync="isShowDrawer"
      direction="btt"
    >
      <div>
        <div class="drawer_header">
          <div class="left">
            <div class="item" @click="handleClickTableSetting('detail')">
              <i class="iconfont iconicon-chuansuokuang" />表格设置
            </div>
          </div>
          <div class="right">
            <!-- <div class="boxItem">
              <span style="margin-right: 10px">状态：</span>
              <Checkbox
                :indeterminate="indeterminate3"
                :value="checkAll3"
                @click.prevent.native="handleCheckAll3"
              >全选</Checkbox>
              <CheckboxGroup v-model="checkAllGroup3" @on-change="checkAllGroupChange3">
                <Checkbox :label="item.name" v-for="item in boxList3" :key="item.name"></Checkbox>
              </CheckboxGroup>
            </div> -->
          </div>
        </div>
        <Table
          :columns="tableColumnsReady2"
          :loading="loading2"
          :data="tableData2"
          border
        ></Table>
        <Page
          class-name="page"
          :total="listData2.total"
          show-elevator
          show-total
          :page-size="listData2.pageSize"
          :current="listData2.pageNum"
          @on-change="pageNumberChange2"
          @on-page-size-change="pageSizeChange2"
        />
      </div>
    </el-drawer>
    <TableSetting
      :chuansuoDialogVisible.sync="chuansuoDialogVisible"
      :routerPath="routerPath"
      :theadDateReal="theadDateReal"
      @tableDataList="tableDataList"
    />
  </div>
</template>

<script>
import TableSetting from "../../components/home/tableSetting.vue";
export default {
  components: {
    TableSetting
  },
  data() {
    return {
      TotalNumTotal: 0, // 匹数合计
      ClothQuantityTotal: 0, // 数量合计
      loading1: false,
      loading2: false,
      statusSelectList: [], // 状态下拉框选择
      docNumberSelectList: [], // 单据类型下拉框选择

      planOrderNumber: "", // 坯布指示单（采购单号）
      articleNumber: "", // 坯布货号、成品货号
      docType: "", // 单据类型
      docDate: "", // 单据日期
      docNumber: "", // 单据编号
      status: "", // 状态

      unitName: "", // 单位名称
      takeOn: "", // 担当
      receiptNumber: "", // 收付单号
      productNumber: "", // 品名
      color: "", // 颜色
      deviceNumber: "", // 机台号
      contractNum: "", // 合同号

      chuansuoDialogVisible: false, // 表格设置显示与否
      routerPath: "",
      theadDateReal: "",
      tableDataList: "",
      theadDateReal1: [],
      theadDateReal2: [],

      isShowDrawer: false, // 是否展示下方的抽屉弹窗
      filterList: [
        // 条件筛选的列表
        {
          number: 1,
          name: "工单单号"
        },
        {
          number: 2,
          name: "订单单号"
        }
      ],

      showUserDefined: false, // 是否展示自定义筛选模块
      showSelect: false, // true展示多选框，false展示筛选条件

      indeterminate1: false, // 只负责样式控制,true为是
      checkAll1: false, // 是否全选
      checkAllGroup1: [], // 已选的多选框的存储
      checkAllGroupShow1: [], // 筛选条件的数组

      boxList1: [
        // 多选框的列表
        {
          name: "单位名称"
        },
        {
          name: "担当"
        },
        {
          name: "应付单号"
        },
        {
          name: "品名"
        },
        {
          name: "颜色"
        },
        {
          name: "机台号"
        }
      ],

      indeterminate2: false, // 只负责样式控制,true为是
      checkAll2: false, // 是否全选
      checkAllGroup2: [], // 已选的多选框的存储

      boxList2: [
        // 多选框的列表
        {
          name: "待穿纱"
        },
        {
          name: "待改机"
        },
        {
          name: "量产中"
        }
      ],
      indeterminate3: false, // 只负责样式控制,true为是
      checkAll3: false, // 是否全选
      checkAllGroup3: [], // 已选的多选框的存储
      boxList3: [
        // 多选框的列表
        {
          name: "待穿纱"
        },
        {
          name: "待改机"
        },
        {
          name: "量产中"
        }
      ],
      tableColumnsReady1: [
        {
          title: "状态",
          key: "ztgcoStatus",
          minWidth: 120
        },
        {
          title: "单据日期",
          key: "ztgcoDocDate",
          minWidth: 180
        },
        {
          title: "单据编号",
          key: "ztgcoDocNumber",
          minWidth: 160
        },
        {
          title: "单据类型",
          key: "ztgcoDocType",
          minWidth: 150
        },
        {
          title: "关联单号",
          key: "ztgcoOrderNumber",
          minWidth: 160
        },
        {
          title: "合同号",
          key: "ztgcoiContractNum",
          minWidth: 150,
          align: "left"
        },
        {
          title: "坯布指示单号",
          key: "ztgcoShipmentNumber", // 原ztgcoiPurchaseOrderNum改为ztgcoShipmentNumber
          minWidth: 150,
          align: "left"
        },
        {
          title: "坯布货号",
          key: "ztgcoiBarcode",
          minWidth: 180,
          align: "left"
        },
        {
          title: "担当",
          key: "ztgcoTakeOn",
          minWidth: 120
        },
        {
          title: "单位编号",
          key: "ztgcoUnitNumber",
          minWidth: 120
        },
        {
          title: "单位名称",
          key: "ztgcoUnitName",
          minWidth: 200
        },
        {
          title: "匹数",
          key: "ztgcoClothQuantity",
          minWidth: 100,
          render: (h, params) => {
            return h("span", params.row.ztgcoClothQuantity);
          }
        },
        {
          title: "数量",
          key: "ztgcoTotalNum",
          minWidth: 100,
          render: (h, params) => {
            return h("span", params.row.ztgcoTotalNum);
          }
        },
        {
          title: "关联收付单号",
          key: "ztgcoReceiptNumber",
          minWidth: 150
        },
        {
          title: "详细描述",
          key: "ztgcoDetails",
          minWidth: 200
        },
        {
          title: "公司订单号",
          key: "ztgcoComOrderNumber",
          minWidth: 200
        }
      ],
      userFilds1: [],
      tableColumns1: [],
      tableData1: [],
      listData1: {
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      tableColumnsReady2: [
        {
          title: "布卷条码",
          key: "ztgcoiClothBarCode",
          minWidth: 200
        },
        {
          title: "采购单号",
          key: "ztgcoiPurchaseOrderNum",
          minWidth: 200
        },
        {
          title: "合同号",
          key: "ztgcoiContractNum",
          minWidth: 200
        },
        {
          title: "坯布指示单单号",
          key: "ztgcoiPurchaseOrderNum",
          minWidth: 150,
          align: "left"
        },
        {
          title: "坯布货号",
          key: "ztgcoiBarcode",
          minWidth: 130,
          align: "left"
        },
        {
          title: "货号",
          key: "ztgcoiPurchaseCargoNum",
          minWidth: 130
        },
        {
          title: "品名",
          key: "ztgcoiPurchaseName",
          minWidth: 200
        },
        {
          title: "缸号",
          key: "ztgcoiVatNum",
          minWidth: 200
        },
        {
          title: "染整单号",
          key: "ztgcoiDyeingOrderNum",
          minWidth: 200
        },
        {
          title: "颜色",
          key: "ztgcoiColor",
          minWidth: 200
        },
        {
          title: "花型",
          key: "ztgcoiDesignType",
          minWidth: 200
        },
        {
          title: "批次",
          key: "ztgcoiBatch",
          minWidth: 200
        },
        {
          title: "更改机台号",
          key: "ztgcoiChangeDevice",
          minWidth: 200
        },
        {
          title: "匹数",
          key: "ztgcoiClothNum",
          minWidth: 200,
          render: (h, params) => {
            return h("span", params.row.ztgcoiClothNum);
          }
        },
        {
          title: "数量（KG）",
          key: "ztgcoiNum",
          minWidth: 200,
          render: (h, params) => {
            return h("span", params.row.ztgcoiNum.toFixed(2));
          }
        },
        {
          title: "增减数",
          key: "ztgcoiIncDecNum",
          minWidth: 200
        },
        {
          title: "应收数",
          key: "ztgcoiIncomeNum",
          minWidth: 200
        },
        {
          title: "采购货号",
          key: "ztgcoiPurchaseCargoNum",
          minWidth: 200
        },
        {
          title: "客户款号",
          key: "ztgcoiClientNum",
          minWidth: 200
        },
        {
          title: "存放位置",
          key: "ztgcoiLocation",
          minWidth: 200
        },
        {
          title: "卷号",
          key: "ztgcoiRollNum",
          minWidth: 200
        },
        {
          title: "单价",
          key: "ztgcoiPrice",
          minWidth: 200
        },
        {
          title: "退货匹数",
          key: "ztgcoiReturnClothNum",
          minWidth: 200
        },
        {
          title: "退货数量",
          key: "ztgcoiReturnNum",
          minWidth: 200
        }
      ],
      tableColumns2: [],
      ztgcoId: "",
      userFilds2: [],
      tableData2: [],
      listData2: {
        pageSize: 8,
        pageNum: 1,
        total: 0
      }
    };
  },
  created() {
    this.tableDataList1();
    this.getDocNumberSelectList();
    this.getStatusSelectList();
  },
  methods: {
    // 同步表格数据
    syncTableData() {
      this.loading1 = true;
      this.axios({
        url: "/dtsum/zongtong/basic/CommonController/synTable",
        method: "get"
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.$Message.success("同步成功！");
            this.tableDataList1();
          } else {
            this.$Message.warning("同步失败！");
          }
          this.loading1 = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 累计的函数
    handleSummary1({ columns, data }) {
      const sums = {};
      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: "累计"
          };
          return;
        }
        data.map((item) => Number(item[key]));
        if (key === "ztgcoClothQuantity") {
          sums[key] = {
            key,
            value: this.ClothQuantityTotal
          };
        } else if (key === "ztgcoTotalNum") {
          sums[key] = {
            key,
            value: this.TotalNumTotal
          };
        } else {
          sums[key] = {
            key,
            value: ""
          };
        }
      });

      return sums;
    },
    // 获取单据编号的下拉框信息
    getDocNumberSelectList() {
      this.axios({
        url: "/dtsum/zongtong/basic/CommonController/commonSelect",
        method: "get",
        params: {
          flag: "greyclothouttype"
        }
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.docNumberSelectList = res.data.body.selectList;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取状态的下拉框信息
    getStatusSelectList() {
      this.axios({
        url: "/dtsum/zongtong/basic/CommonController/commonSelect",
        method: "get",
        params: {
          flag: "greyclothoutstatus"
        }
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.statusSelectList = res.data.body.selectList;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 点击详情显示详情表
    handleShowDetail(ztgcoId) {
      this.isShowDrawer = true;
      this.tableDataList2();
    },
    // 导出
    handleClickExport() {
      window.location.href = `${
        this.baseUrl
      }/dtsum/zongtong/greycloth/greyClothController/clothOutputSheetExport?purchaseOrderNum=${
        this.planOrderNumber
      }&barcode=${this.articleNumber}&docType=${this.docType}&docDateStart=${
        this.docDate[0]
          ? this.moment(this.docDate[0]).format("YYYY-MM-DD HH:mm:ss")
          : ""
      }&docDateEnd=${
        this.docDate[1]
          ? this.moment(this.docDate[1]).format("YYYY-MM-DD HH:mm:ss")
          : ""
      }&docNumber=${this.docNumber}&status=${this.status}&unitName=${
        this.unitName
      }&takeOn=${this.takeOn}&receiptNumber=${
        this.receiptNumber
      }&productNumber=${this.productNumber}&color=${this.color}&deviceNumber=${
        this.deviceNumber
      }&contractNum=${this.contractNum}`;
    },
    handleClickTableSetting(value) {
      switch (value) {
        case "main":
          this.routerPath =
            "/dtsum/zongtong/greycloth/greyClothController/clothOutputSheetSearch";
          this.theadDateReal = this.tableColumns1;
          this.tableDataList = this.tableDataList1;
          break;
        case "detail":
          this.routerPath =
            "/dtsum/zongtong/greycloth/greyClothController/clothOutputSheetDetail";
          this.theadDateReal = this.tableColumns2;
          this.tableDataList = this.tableDataList2;
          break;
        default:
          this.routerPath =
            "/dtsum/zongtong/greycloth/greyClothController/clothOutputSheetSearch";
          this.theadDateReal = this.tableColumns1;
          this.tableDataList = this.tableDataList1;
      }
      this.chuansuoDialogVisible = true;
    },
    // 获取主表的信息
    tableDataList1() {
      this.loading1 = true;
      this.axios({
        url:
          "/dtsum/zongtong/greycloth/greyClothController/clothOutputSheetSearch",
        method: "get",
        params: {
          pageSize: this.listData1.pageSize, // 页大小
          pageNum: this.listData1.pageNum, // 页号

          params: {
            purchaseOrderNum: this.planOrderNumber, // 坯布指示单（采购单号）
            barcode: this.articleNumber, // 坯布货号、成品货号
            docType: this.docType, // 单据类型
            docDateStart: this.docDate[0]
              ? this.moment(this.docDate[0]).format("YYYY-MM-DD HH:mm:ss")
              : "", // 单据日期开始
            docDateEnd: this.docDate[1]
              ? this.moment(this.docDate[1]).format("YYYY-MM-DD HH:mm:ss")
              : "", // 单据日期结束
            docNumber: this.docNumber, // 单据编号
            status: this.status, // 状态

            unitName: this.unitName, // 单位名称
            takeOn: this.takeOn, // 担当
            receiptNumber: this.receiptNumber, // 收付单号
            productNumber: this.productNumber, // 品名
            color: this.color, // 颜色
            deviceNumber: this.deviceNumber, // 机台号
            contractNum: this.contractNum // 合同号
          }
        }
      })
        .then((res) => {
          this.loading1 = false;
          if (res.data.success === 1) {
            const clothOutputSheetList = res.data.body.clothOutputSheetList;
            this.tableData1 = JSON.parse(
              JSON.stringify(clothOutputSheetList.list)
            );
            this.listData1.pageSize = clothOutputSheetList.pageSize;
            this.listData1.pageNum = clothOutputSheetList.pageNum;
            this.listData1.total = clothOutputSheetList.total;
            this.userFilds1 = res.data.body.userFilds;
            this.ztgcoClothQuantitySum = res.data.body.ztgcoClothQuantitySum;
            this.ztgcoTotalNumSum = res.data.body.ztgcoTotalNumSum;
            this.TotalNumTotal = res.data.body.TotalNumTotal;
            this.ClothQuantityTotal = res.data.body.ClothQuantityTotal;
            this.tableColumns1 = [
              {
                title: "序号",
                width: 80,
                align: "center",
                render: (h, params) => {
                  return h(
                    "span",
                    params.index +
                      (this.listData1.pageNum - 1) * this.listData1.pageSize +
                      1
                  );
                }
              },
              {
                title: "操作",
                key: "action",
                width: 100,
                align: "center",
                render: (h, params) => {
                  return h("div", [
                    h(
                      "span",
                      {
                        style: {
                          fontSize: "14px",
                          color: "#256DE6",
                          cursor: "pointer"
                        },
                        on: {
                          click: () => {
                            this.ztgcoId = params.row.ztgcoId;
                            this.handleShowDetail(params.row.ztgcoId);
                          }
                        }
                      },
                      "详情"
                    )
                  ]);
                }
              }
            ];
            this.userFilds1.map((item) => {
              this.tableColumnsReady1.map((itemIn) => {
                if (itemIn.key === item.field) {
                  this.tableColumns1.push(itemIn);
                }
              });
            });
          }
        })
        .catch((err) => {
          this.loading1 = false;
          console.log(err);
        });
    },
    // 获取详情表的信息
    tableDataList2() {
      this.loading2 = true;
      this.axios({
        url:
          "/dtsum/zongtong/greycloth/greyClothController/clothOutputSheetDetail",
        method: "get",
        params: {
          ztgcoId: this.ztgcoId, // 唯一id
          pageSize: this.listData2.pageSize, // 页大小
          pageNum: this.listData2.pageNum // 页号
        }
      })
        .then((res) => {
          this.loading2 = false;
          if (res.data.success === 1) {
            const clothEntrySheetDetailList =
              res.data.body.clothEntrySheetDetailList;
            this.tableData2 = JSON.parse(
              JSON.stringify(clothEntrySheetDetailList.list)
            );
            this.listData2.pageSize = clothEntrySheetDetailList.pageSize;
            this.listData2.pageNum = clothEntrySheetDetailList.pageNum;
            this.listData2.total = clothEntrySheetDetailList.total;
            this.userFilds2 = res.data.body.userFilds;
            this.tableColumns2 = [
              {
                title: "序号",
                width: 80,
                align: "center",
                render: (h, params) => {
                  return h(
                    "span",
                    params.index +
                      (this.listData2.pageNum - 1) * this.listData2.pageSize +
                      1
                  );
                }
              }
            ];
            this.userFilds2.map((item) => {
              this.tableColumnsReady2.map((itemIn) => {
                if (itemIn.key === item.field) {
                  this.tableColumns2.push(itemIn);
                }
              });
            });
          }
        })
        .catch((err) => {
          this.loading2 = false;
          console.log(err);
        });
    },
    // 自定义筛选
    handleClickfilter() {
      this.showSelect = !this.showSelect;
      if (this.showSelect === true) {
        // 从筛选条件跳转到多选框
        this.showUserDefined = true;
        this.checkAllGroup1 = this.checkAllGroupShow1;
      } else {
        // 从多选框跳到筛选条件
        if (this.checkAllGroupShow1.length === 0) {
          this.showUserDefined = false;
        }
      }
    },
    // 确认多选框跳转到筛选条件
    handleClickConfirm() {
      this.showSelect = false;
      this.checkAllGroupShow1 = this.checkAllGroup1;
      if (this.checkAllGroupShow1.length === 0) {
        this.showUserDefined = false;
      }
    },
    // 跳转到筛选条件的多选框
    handleClickSelectButton() {
      this.showSelect = true;
    },
    pageSizeChange1(pageSize) {
      this.listData1.pageSize = pageSize;
      this.listData1.pageNum = 1;
      this.tableDataList1();
    },
    pageNumberChange1(pageNum) {
      this.listData1.pageNum = pageNum;
      this.tableDataList1();
    },
    pageSizeChange2(pageSize) {
      this.listData2.pageSize = pageSize;
      this.listData2.pageNum = 1;
      this.tableDataList2();
    },
    pageNumberChange2(pageNum) {
      this.listData2.pageNum = pageNum;
      this.tableDataList2();
    },
    handleCheckAll1() {
      // 全选
      if (this.indeterminate1) {
        this.checkAll1 = false;
      } else {
        this.checkAll1 = !this.checkAll1;
      }
      this.indeterminate1 = false;

      if (this.checkAll1) {
        this.checkAllGroup1 = [
          "单位名称",
          "担当",
          "应付单号",
          "品名",
          "颜色",
          "机台号",
          "合同号"
        ]; // 这里改为全部的状态
      } else {
        this.checkAllGroup1 = [];
      }
    },
    checkAllGroupChange1(data) {
      if (data.length === 7) {
        this.indeterminate1 = false;
        this.checkAll1 = true;
      } else if (data.length > 0) {
        this.indeterminate1 = true;
        this.checkAll1 = false;
      } else {
        this.indeterminate1 = false;
        this.checkAll1 = false;
      }
    },
    handleCheckAll2() {
      // 全选
      if (this.indeterminate2) {
        this.checkAll2 = false;
      } else {
        this.checkAll2 = !this.checkAll2;
      }
      this.indeterminate2 = false;

      if (this.checkAll2) {
        this.checkAllGroup2 = ["待穿纱", "待改机", "量产中"]; // 这里改为全部的状态
      } else {
        this.checkAllGroup2 = [];
      }
    },
    checkAllGroupChange2(data) {
      if (data.length === 3) {
        this.indeterminate2 = false;
        this.checkAll = true;
      } else if (data.length > 0) {
        this.indeterminate2 = true;
        this.checkAll = false;
      } else {
        this.indeterminate2 = false;
        this.checkAll = false;
      }
    },
    handleCheckAll3() {
      // 全选
      if (this.indeterminate3) {
        this.checkAll3 = false;
      } else {
        this.checkAll3 = !this.checkAll2;
      }
      this.indeterminate3 = false;

      if (this.checkAll3) {
        this.checkAllGroup3 = ["待穿纱", "待改机", "量产中"]; // 这里改为全部的状态
      } else {
        this.checkAllGroup3 = [];
      }
    },
    checkAllGroupChange3(data) {
      if (data.length === 3) {
        this.indeterminate3 = false;
        this.checkAll = true;
      } else if (data.length > 0) {
        this.indeterminate3 = true;
        this.checkAll = false;
      } else {
        this.indeterminate3 = false;
        this.checkAll = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#clothOutput {
  font-size: $--fontsize-title;
  .filter {
    font-size: $--fontsize-small;
    display: flex;
    position: relative;
    .left {
      .filterItem {
        display: inline-flex;
        margin: 0 50px 20px 0;
        div {
          line-height: 32px;
        }
        &:last-of-type {
          float: right;
        }
      }
    }
  }
  .userDefined {
    font-size: $--fontsize-small;
    background: #f4f7fe;
    padding: 16px 8px;
    border-radius: $--radius-standard;
    position: relative;
    .divide {
      display: inline-block;
      width: 1px;
      height: 10px;
      margin-top: 2px;
      margin-right: 10px;
      background-color: #dcdada;
    }
    .triangle {
      position: absolute;
      top: -10px;
      right: 90px;
      background: #fff;
      font-size: 0;
      line-height: 0;
      width: 0;
      height: 0;
      border-color: transparent transparent #f5f5f5 transparent;
      border-style: solid;
      border-width: 0px 5px 10px 5px;
    }
    .left {
      .filterItem {
        display: flex;
        margin: 0 50px 10px 0;
      }
    }
    .filter_container {
      display: flex;
      flex-wrap: wrap;
    }
    .right {
      margin-top: 17px;
      text-align: right;
    }
  }
  .box {
    font-size: $--fontsize-small;
    .boxItem {
      margin: 10px 0;
    }
  }
  .button {
    font-size: $--fontsize-small;
    .buttonRow {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      color: #256de6;
      .item {
        display: inline-block;
        cursor: pointer;
      }
      .left {
        .item {
          margin-right: 30px;
        }
      }
      .right {
        .item {
          margin-left: 10px;
        }
        .filterButton {
          width: 120px;
          margin-right: 30px;
          height: 32px;
          line-height: 32px;
          text-align: center;
        }
      }
    }
  }
  .page {
    font-size: $--fontsize-small;
    text-align: center;
    margin-top: 10px;
  }
  .drawer_header {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular;
    margin-bottom: 10px;
    .left {
      display: flex;
      .item {
        margin-right: 10px;
        color: #256de6;
        i {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
<style lang="scss">
#clothOutput {
  .filter {
    .right {
      .ivu-btn {
        position: absolute;
        bottom: 10px;
        color: $--color-blue-standard;
      }
    }
  }
  .userDefined {
    .left {
      .ivu-checkbox-wrapper {
        margin-right: 12px;
      }
    }
    .right {
      .cancel {
        position: absolute;
        left: 0;
        bottom: 5px;
      }
      .confirm {
        position: absolute;
        right: 10px;
        bottom: 5px;
      }
      .select_button {
        color: $--color-blue-standard;
        display: flex;
        position: absolute;
        top: calc(50% - 5px);
        transform: translateY(-50%);
        &::before {
          content: "";
          display: block;
          width: 2px;
          height: 20px;
          margin-right: 10px;
          background-color: #dcdada;
        }
      }
    }
  }
  .el-drawer__body {
    padding: 0 20px;
  }
  .ivu-select-selection {
    height: 32px !important;
  }
  .ivu-select-placeholder {
    line-height: 32px !important;
    height: 32px !important;
  }
  .ivu-select-selected-value {
    line-height: 32px !important;
    height: 32px !important;
  }
  .ivu-input {
    height: 32px !important;
  }
  .ivu-input-suffix i {
    line-height: 32px !important;
  }
  .ivu-icon-ios-time-outline {
    line-height: 32px !important;
  }
  .ivu-checkbox-default {
    display: inline-block;
  }
}
</style>
