import { render, staticRenderFns } from "./clothOutput.vue?vue&type=template&id=78cbfd0c&scoped=true&"
import script from "./clothOutput.vue?vue&type=script&lang=js&"
export * from "./clothOutput.vue?vue&type=script&lang=js&"
import style0 from "./clothOutput.vue?vue&type=style&index=0&id=78cbfd0c&lang=scss&scoped=true&"
import style1 from "./clothOutput.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78cbfd0c",
  null
  
)

export default component.exports